import lorenso from '../images/team/lorenso.jpg'
import thomas from '../images/team/thomas.jpeg'
import frits from '../images/team/frits.jpg'
import walter from '../images/team/walter.jpg'
import ankie from '../images/team/ankie.jpg'
import Image from "next/image";
import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/outline";
import {useState} from "react";

const people = [

    {
        name: 'Walter van den Elsen',
        role: 'Adviseur',
        image: walter,
        bio: "<p>Walter van den Elsen werkt al bijna vijfentwintig jaar als zelfstandig organisatieadviseur in het onderwijs. &ldquo;De rode draad in mijn werk is scholen en de mensen die daar werken helpen om het beste uit zichzelf &eacute;n elkaar te halen. Nog elke dag ben ik onder de indruk van inspanningen van docenten, schoolleiders en onderwijsbesturen om onze kinderen voor te bereiden op hun toekomst. Tegelijkertijd herken ik dat dit werk voor hen steeds ingewikkelder en zwaarder wordt.&rdquo;</p><p>&ldquo;Toen &eacute;&eacute;n van de initiatiefnemers me over Bijlesplein vertelde, sprak het me meteen aan. Ik zie scholen als leerwerkgemeenschappen en voor mij is Bijlesplein een nieuwe manier om leerlingen nog meer van elkaar te laten leren. Ik was eerlijk gezegd ook verbluft door wat Bijlesplein dankzij moderne techniek aan flexibele oplossingen kan bieden. Om &eacute;n jongeren van elkaar te laten leren &eacute;n wat het bij docenten aan dagelijks regelwerk kan wegnemen om dit mogelijk te maken.&rdquo;<p/><p>&ldquo;In de samenleving, maar ook op school bij leerlingen, zie je dat iedereen steeds meer in haar of zijn eigen bubbel terechtkomt en verblijft. Bijlesplein is voor mij een manier om die afgrenzing te doorbreken, onverwachte ontmoetingen tussen leerlingen te cre&euml;ren en hen binnen de eigen, veilige, schoolgemeenschap nieuwe en waardevolle contacten op te laten doen.&rdquo;</p>",
        email: 'walter@bijlesplein.nl',
        phone: '(+31) 6 22 211 888',
        linkedinUrl: 'https://www.linkedin.com/in/waltervandenelsen/',
    },
    {
        name: 'Frits Timmermans',
        role: 'Adviseur',
        image: frits,
        bio: '<p>Vanwege zijn voorliefde voor het vak Geschiedenis begon Frits aan de lerarenopleiding Geschiedenis. Echter bleek bij zijn eerste stage dat het werken met leerlingen minstens zo leuk was dan het vak zelf. &ldquo;Na jaren les te hebben gegeven ben ik via de rol van teamleider en afdelingsleider in de schoolleiding terecht gekomen. Dat was een erg leuke school in Eindhoven en daar is het idee van Bijlesplein ontstaan. Lorenso en Thomas kende ik van een ander project en met ons drie&euml;n zijn we dit mooie platform verder gaan ontwikkelen.&rdquo;</p><p>&ldquo;Naast dat het effectief voor leerlingen om elkaar te helpen groot is, is kansengelijkheid vergroten een doel voor het ontwikkelen van het platform. Kansenongelijkheid is een groot probleem binnen ons land terwijl onderwijs de grote gelijkmaker moet zijn. Echter, door allerlei omstandigheden is het schaduwonderwijs in Nederland gegroeid. Er zijn ontzettend veel partijen met goede bedoelingen ontstaan. Maar door de groei zijn er ook organisaties die zelfs in handen zijn van investeringsmaatschappijen. Een bijles van een uur kost dan al snel tussen de 20 en 40 euro. Niet iedereen kan dat betalen en naar mijn mening is het een maatschappelijke taak van het onderwijs om kansengelijkheid te vergroten. Dat kan door met ons platform te gaan werken.&rdquo;</p>',
        email: 'frits@bijlesplein.nl',
        phone: '(+31) 6 53 571 911',
        linkedinUrl: 'https://www.linkedin.com/in/frits-timmermans-38036722/',
    },
    {
        name: 'Ankie Romme',
        role: 'Marketing Manager',
        image: ankie,
        bio: '<p>Ankie Romme is een ervaren communicatie- en marketingadviseur. &ldquo;Ik werd direct enthousiast toen ik met de initiatiefnemers van Bijlesplein in contact kwam. Een simpele oplossing met grote impact binnen het onderwijs. En dat mag, of misschien wel moet, de hele onderwijswereld weten. Daar zit mijn uitdaging. Bekendheid geven aan dit platform om scholen te helpen en kansenongelijkheid te verminderen.&rdquo;</p><p>&ldquo;Zelf heb ik een zorgeloze en fijne schooltijd gehad. Dat gun ik iedereen. Dat zit &lsquo;m natuurlijk in veel factoren, maar voor een deel kan dat opgevangen worden door op een laagdrempelige manier bijles aan te bieden. Met Bijlesplein helpen we scholen daarbij.&rdquo;</p>',
        email: 'ankie@bijlesplein.nl',
        phone: '(+31) 6 31 969 720',
        linkedinUrl: 'https://www.linkedin.com/in/ankieromme/',
    },
    {
        name: 'Lorenso D\'Agostino',
        role: 'Techniek & Development',
        image: lorenso,
        bio: '<p>Freelance software engineer Lorenso D&rsquo;Agostino is gefascineerd door van het volledige proces van een idee omzetten naar een digitaal product. &ldquo;Als freelancer heb ik de volledige controle over dit proces. Een paar jaar geleden kwam ik samen met Thomas van Zadelhoff in contact met een school in Eindhoven die tegen een probleem aanliep: leerlingen die bijles wilden krijgen of geven, konden elkaar niet eenvoudig vinden. De koppeling tussen deze leerlingen werd handmatig gemaakt. Dat nam veel tijd in beslag. Om dit proces te vereenvoudigen, besloten we samen een platform te ontwikkelen genaamd Bijlesplein.&rdquo;</p><p>&ldquo;Het valt mij op dat veel commerci&euml;le partijen exorbitant hoge bedragen vragen voor bijles. Uit eigen ervaring weet ik dat niet alle leerlingen financieel in staat zijn om dergelijke bijlessen te kunnen betalen. Dat vind ik zorgwekkend. Onderwijs zou immers toegankelijk moeten zijn voor iedereen!&rdquo;</p><p>&ldquo;Bijlesplein maakt het mogelijk voor leerlingen om elkaar gemakkelijk online te vinden, op een manier die voor hen vertrouwd is. Bovendien is het laten geven van bijles door medeleerlingen veel effectiever dan het inschakelen van een dure externe tutor. Dit is iets wat ik zelf als zeer positief heb ervaren tijdens mijn middelbareschooltijd.&rdquo;</p>',
        email: 'lorenso@bijlesplein.nl',
        phone: '(+31) 6 50 277 538',
        linkedinUrl: 'https://www.linkedin.com/in/lorenso/',
    },
    {
        name: 'Thomas van Zadelhoff',
        role: 'Techniek & Development',
        image: thomas,
        bio: '<p>Thomas van Zadelhoff is ondernemer in de web- en app-branche met als missie mooie producten te maken die het leven makkelijker, leuker en beter maken. &ldquo;Als medebedenker van Bijlesplein ben ik gepassioneerd door het cre&euml;ren van oplossingen die het onderwijs toegankelijker maken voor iedereen. Toen Lorenso en ik voor het eerst in contact kwamen met een school in Eindhoven die worstelde met het verbinden van leerlingen voor bijlessen, zagen we een kans om iets te betekenen. Het handmatig koppelen van leerlingen kostte veel tijd en energie, en we wisten dat er een betere manier moest zijn. Door onze gezamenlijke technische kennis en de ervaring van Frits die op die school werkte, wisten we dit probleem te vertalen naar een mooie oplossing.&rdquo;</p><p>&ldquo;Bijlesplein is ons antwoord op dit probleem. We hebben een platform ontwikkeld waarmee leerlingen elkaar eenvoudig kunnen vinden en ondersteunen. Allemaal online en in een eigen vertrouwde omgeving. Door middel van ons platform maken we bijlessen niet alleen gemakkelijker toegankelijk, maar ook betaalbaarder voor alle leerlingen.&#8232;Als voorstander van kansengelijkheid geloof ik sterk in de kracht van leerlingen die elkaar helpen groeien. Door gebruik te maken van elkaars kennis en ervaring kunnen leerlingen niet alleen hun prestaties verbeteren, maar ook hun zelfvertrouwen vergroten en een waardevolle (sociale) band opbouwen. Ik ben er trots op dat we met Bijlesplein een verschil kunnen maken in het leven van zoveel leerlingen.&rdquo;</p>',
        email: 'thomas@bijlesplein.nl',
        phone: '(+31) 6 40 238 633',
        linkedinUrl: 'https://www.linkedin.com/in/thomasvzadelhoff/',
    },
]

export function Person({person}) {
    const [showMore, setShowMore] = useState(false)

    return <li key={person.name}>
        <div className="flex flex-col gap-4 md:grid grid-cols-6 md:gap-8">
            <div className="col-span-2">
                <div className="aspect-w-3 aspect-h-2">
                    <Image className="rounded-lg object-cover shadow-lg"
                           style={{objectPosition: '50% 30%'}} src={person.image}
                           alt={person.name}/>
                </div>
            </div>
            <div className="text-lg font-medium col-span-4">
                <div className="flex flex-col md:flex-row md:items-center md:gap-4">
                    <div className="flex justify-between items-center">
                        <h3 className="text-xl font-semibold">{person.name}</h3>
                        <a href={person.linkedinUrl}
                           className="text-gray-400 hover:text-gray-500 block md:hidden">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="h-5 w-5" aria-hidden="true" fill="currentColor"
                                 viewBox="0 0 20 20">
                                <path
                                    fillRule="evenodd"
                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </a>
                    </div>
                    <p className="text-blue-600">{person.role}</p>
                </div>
                <ul role="list" className="flex flex-col md:flex-row md:items-center md:gap-4 pt-1">
                    <li>
                        <a href={`mailto:${person.email}`}
                           className="text-gray-400 hover:text-gray-500 flex items-center">
                            <span className="sr-only">Email</span>
                            <EnvelopeIcon className="h-5 w-5 mr-2"/>
                            <span>{person.email}</span>
                        </a>
                    </li>
                    <li>
                        <a href={`tel:${person.phone}`}
                           className="text-gray-400 hover:text-gray-500 flex items-center">
                            <span className="sr-only">Telefoon</span>
                            <PhoneIcon className="h-5 w-5 mr-2"/>
                            <span>{person.phone}</span>
                        </a>
                    </li>
                    <li>
                        <a href={person.linkedinUrl}
                           className="text-gray-400 hover:text-gray-500 hidden md:block">
                            <span className="sr-only">LinkedIn</span>
                            <svg className="h-5 w-5" aria-hidden="true" fill="currentColor"
                                 viewBox="0 0 20 20">
                                <path
                                    fillRule="evenodd"
                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </a>
                    </li>
                </ul>

                {showMore ?
                    <div
                        className="text-gray-500 pt-4 text-base prose"
                        dangerouslySetInnerHTML={{__html: person.bio}}
                    />
                    :
                    <div>
                        <div
                            className="text-gray-500 pt-4 text-base prose"
                            dangerouslySetInnerHTML={{__html: person.bio.substring(0, 400) + "..."}}
                        />
                        {!showMore && <button
                            className="text-base text-blue-500 inline-block hover:underline"
                            onClick={() => setShowMore(true)}
                        >Lees meer</button>}
                    </div>
                }


            </div>
        </div>
    </li>
}

export function Team() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-12 px-6 lg:px-8 lg:py-24">
                <div className="space-y-12">
                    <div className="space-y-5 sm:space-y-4">
                        <h2 className="text-3xl font-bold tracking-tight sm:text-4xl font-display">Ons Team</h2>
                        <p className="text-xl text-gray-500 max-w-3xl">
                            Ons team bestaat uit enthousiaste en betrokken professionals met een
                            passie voor onderwijs. Wij geloven in het vergroten van kennisoverdracht en het verkleinen
                            van
                            kansenongelijkheid in het onderwijs.
                        </p>
                    </div>
                    <div>
                        <ul
                            role="list"
                            className="space-y-12 sm:grid sm:grid-cols-1 sm:gap-x-6 sm:gap-y-20 sm:space-y-0 lg:gap-x-8"
                        >
                            {people.map((person) => <Person key={person.email} person={person} />)}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
