import Image from "next/image";
import wave from "@/images/wave.png"

export default function HeaderSimple({image, top_title, title, text}) {
    return (
        <div className="bg-white">
            {image &&
                <div className="relative h-60 sm:h-[27rem] overflow-hidden">
                    <Image
                        src={image}
                        alt="Main image"
                        className="h-full w-full object-cover"
                    />
                    <Image
                        src={wave}
                        alt="Wave"
                        className="h-24 w-full absolute -bottom-10"
                    />
                </div>
            }

            <div className="mx-auto max-w-5xl py-6 px-6 sm:py-16 lg:px-8">
                <div className="text-center">
                    {top_title && <h2 className="text-lg font-semibold text-blue-600">{top_title}</h2>}
                    <p className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl font-display">
                        {title}
                    </p>
                    <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-600 text-left">
                        {text}
                    </p>
                </div>
            </div>
        </div>
    )
}
